.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* boostrap form style */

.form-inline {
  width: 100%;
}

.form-group {
  width: 90%;
}

.input-group {
  width: 90% !important;
}

.form-control {
  width: 80% !important;
}

span.input-group-addon {
  width: 50px !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}